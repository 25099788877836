import { TaskTimer } from 'tasktimer';

export const timer = {
  namespaced : true,
  state      : {
    inactivityTime    : new Date().getTime(),
    timer             : new TaskTimer(1000),
    timerLabel        : '',
    shownRefreshModal : false,
  },
  mutations: {
    setInactivityTime(state: any, payload: any) {

      state.inactivityTime = payload;

    },
    setTimer(state: any, payload: any) {

      state.timer = payload;

    },
    setTimerLabel(state: any, payload: any) {

      state.timerLabel = payload;

    },
    setShownRefreshModal(state: any, payload: any) {

      state.shownRefreshModal = payload;

    },
  },
  actions: {
    setInactivityTime(context: any, payload: any) {

      context.commit('setInactivityTime', payload);

    },
    setTimer(context: any, payload: any) {

      context.commit('setTimer', payload);

    },
    setTimerLabel(context: any, payload: any) {

      context.commit('setTimerLabel', payload);

    },
    setShownRefreshModal(context: any, payload: any) {

      context.commit('setShownRefreshModal', payload);

    },
  },
  getters: {
    inactivityTime(state: any) {

      return state.inactivityTime;

    },
    timer(state: any) {

      return state.timer;

    },
    timerLabel(state: any) {

      return state.timerLabel;

    },
  }
};
