import { createApp } from "vue";
import App from "./App.vue";
window._ = require('lodash');
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css';
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Datepicker from '@vuepic/vue-datepicker';
import VueGtag from "vue-gtag";
import DateRangePicker from '@/views/Dashboard/Shared/DateRangePicker.vue';
import FilterActions from "@/views/Dashboard/Shared/FilterActions.vue";
import TaxIdInput from "@/components/widgets/inputs/TaxIdInput.vue";

import '@vuepic/vue-datepicker/dist/main.css'
import "@/core/plugins/prismjs";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

const app = createApp(App);

app.component('Datepicker', Datepicker);
app.component('DateRangePicker', DateRangePicker);
app.component('FilterActions', FilterActions);
app.component('TaxIdInput', TaxIdInput);

app.use(store);
app.use(router);
app.use(ElementPlus);

app.use(VueGtag, {
    config: {
        id: process.env?.VUE_APP_G_TAG_ID
    }
})

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
