<template>
  <div
    class="form-group"
  >
    <label :for="name">
      <span class="fw-bold">{{ label }}:</span>
    </label>
    <input
      :value="inputValue"
      type="text"
      placeholder="00000000-0"
      autocomplete="off"
      @blur="formatRut"
      @input="onChange"
      class="form-control form-control-solid"
      :style="(errorMessage)?'border: red 1px solid':''"
      :disabled="disabled"
      ref="taxidInputRef"
    />
    <span style="color:#999; font-size: 13px;">
      {{ description }}
    </span>
  </div>
</template>

<script>
import { defineComponent, ref, toRef, watch } from 'vue';
import { useField } from 'vee-validate';
import { format } from 'rut.js';

export default defineComponent({
  props: [ 'modelValue', 'value', 'name', 'label', 'successMessage', 'placeholder', 'description', 'disabled', 'rut' ],
  emits: ['update:modelValue'],
  setup(props, { emit }) {

    const label = ref(props.label);
    const description = ref(props.description);

    const name = toRef(props, 'name');

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      initialValue: props.modelValue,
    });

    const formatRut = (event) => {

      const formatted = format(event.target.value, { dots: false });
      handleBlur(formatted);

      emit('update:modelValue', formatted);

    };

    const onChange = (event) =>  {

      const formatted = format(event.target.value, { dots: false });
      handleChange(formatted);

      emit('update:modelValue', formatted);

    }

    // Actualizar el valor local cuando cambia el prop
    watch(() => props.modelValue, (newValue) => {
      if (newValue !== inputValue.value) {
         handleChange(newValue);
      }
    });

    const taxidInputRef = ref();

    return {
      name,
      inputValue,
      errorMessage,
      meta,
      label,
      description,
      formatRut,
      onChange,
      taxidInputRef,
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
