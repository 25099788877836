<template>
    <div class="btn-group d-flex w-100">        
        <button class="btn btn-secondary me-2" type="submit"><i class="fa-solid fa-filter"></i> Filtrar</button>
        <button type="button" class="btn btn-secondary me-2" @click="cleanUp()"><i class="fa-solid fa-trash" /> Limpiar</button>
        <button type="button" class="btn btn-secondary" @click="exportData()"><i class="fa-solid fa-download"></i> Exportar</button>
    </div>        
  </template>
  <script>
  import { defineComponent } from "vue";  

  export default defineComponent({
    name  : 'FilterActions',    
    props : {
      item: {
        type: Object,
        default: {}
      }
    },
    emits: ['clean', 'export'],
    setup(props, { emit }) {

      function cleanUp() {
        emit('clean')
      }

      function exportData() {
        emit('export')
      } 

      return {
        cleanUp,
        exportData
      }
    },
  })
  </script>
  