import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {array} from "yup";

interface IAppStore {
  params: any;
  code: string;
}

@Module
export default class AppModule extends VuexModule implements IAppStore {

  params: any = Object();
  code: string = '';
  documentDetail: any = [];
  documentList: any = [];
  filterParams: any = Object();

  /**
   * Get params
   * @returns object
   */
  get getParams(): any {
    return this.params;
  }

  /**
   * Get code
   * @returns string
   */
  get getCode(): string {
    return this.code;
  }

  @Mutation
  [Mutations.SET_DOCUMENT_PARAMS](payload) {
    this.params = payload;
  }

  @Action
  [Actions.SET_DOCUMENT_PARAMS](payload) {
    this.context.commit(Mutations.SET_DOCUMENT_PARAMS, payload);
  }

  @Mutation
  [Mutations.SET_DOCUMENT_CODE](payload) {
    this.code = payload;
  }

  @Action
  [Actions.SET_DOCUMENT_CODE](payload) {
    this.context.commit(Mutations.SET_DOCUMENT_CODE, payload);
  }

  @Mutation
  [Mutations.SET_DOCUMENT_DETAIL](payload) {
    this.documentDetail = payload;
  }

  @Action
  [Actions.SET_DOCUMENT_DETAIL](payload) {
    this.context.commit(Mutations.SET_DOCUMENT_DETAIL, payload);
  }

  @Mutation
  [Mutations.SET_DOCUMENT_LIST](payload) {
    this.documentList = payload;
  }

  @Action
  [Actions.SET_DOCUMENT_LIST](payload) {
    this.context.commit(Mutations.SET_DOCUMENT_LIST, payload);
  }

  @Mutation
  [Mutations.SET_FILTER_PARAMS](payload) {
    this.filterParams = payload;
  }

  @Action
  [Actions.SET_FILTER_PARAMS](payload) {
    this.context.commit(Mutations.SET_FILTER_PARAMS, payload);
  }
}
