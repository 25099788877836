import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    value: _ctx.date,
    format: _ctx.format,
    timezone: 'America/Santiago',
    enableTimePicker: true,
    range: "",
    multiCalendars: "",
    autoApply: "",
    placeholder: "Rango de Fecha",
    ref: "datepickerRangeRef",
    clearable: _ctx.clearable
  }, null, 8, ["value", "format", "clearable"]))
}