import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import _ from "lodash";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/tokens",
        name: "Tokens",
        component: () => import("@/views/Dashboard/Token/Tokens.vue"),
        props: (route) => ({
          user: route.query.user,
          enroller: route.query.enroller,
        }),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/tokens/:uuid",
        name: "Token",
        component: () => import("@/views/Dashboard/Token/Token.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/validations",
        name: "Validations",
        component: () => import("@/views/Dashboard/Validation/Validations.vue"),
        props: (route) => ({
          user: route.query.user,
          enroller: route.query.enroller,
        }),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/validations/:uuid",
        name: "Validation",
        component: () =>
          import("@/views/Dashboard/Validation/SingleValidation.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/authorizations",
        name: "Authorizations",
        component: () =>
          import("@/views/Dashboard/Authorization/Authorizations.vue"),
        props: (route) => ({
          user: route.query.user,
          enroller: route.query.enroller,
        }),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/authorizations/:uuid",
        name: "Authorization",
        component: () =>
          import("@/views/Dashboard/Authorization/SingleAuthorization.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        component: () =>
          import("@/views/Dashboard/Subscription/Subscriptions.vue"),
        props: (route) => ({ query: route.query.user }),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/subscriptions/:uuid",
        name: "subscription",
        component: () =>
          import("@/views/Dashboard/Subscription/SubscriptionDetail.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("@/views/Dashboard/Users/Users.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users/:uuid/version/:version",
        name: "User",
        component: () => import("@/views/Dashboard/Users/SingleUser.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/operations",
        name: "Operations",
        component: () => import("@/views/Dashboard/Operations/Operations.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/operations/:uuid",
        name: "single-operation",
        component: () =>
          import("@/views/Dashboard/Operations/SingleOperation.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/tds-rules",
        name: "Service Fees",
        component: () => import("@/views/Dashboard/ServiceFee.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/tds-rules/:uuid",
        name: "Single Service Fee Rule",
        component: () => import("@/views/Dashboard/SingleServiceFeeRule.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cds",
        name: "Service Costs",
        component: () =>
          import("@/views/Dashboard/ServiceCost/ServiceCosts.vue"),
        props: (route) => ({ query: route.query.tds }),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cds/:uuid",
        name: "Single Service Cost",
        component: () =>
          import("@/views/Dashboard/ServiceCost/SingleServiceCost.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/chargebacks",
        name: "Chargebacks",
        props: (route) => ({ query: route.query.cfr }),
        component: () => import("@/views/Dashboard/Chargeback/Chargebacks.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settlements",
        name: "Settlements",
        component: () => import("@/views/Dashboard/Settlement/Settlements.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settlements/:uuid",
        name: "Single Settlement",
        component: () =>
          import("@/views/Dashboard/Settlement/SingleSettlement.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/chargeback-fee-rules",
        name: "Chargebacks Fee Rules",
        component: () => import("@/views/Dashboard/ChargebackFeeRules.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/webhooks",
        name: "Webhooks",
        component: () => import("@/views/Dashboard/Webhook/Webhooks.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/webhooks/:uuid/attempt/:attempt",
        name: "Single Webhook",
        component: () => import("@/views/Dashboard/Webhook/SingleWebhook.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/chargeback-fee-rules/:uuid",
        name: "Single Chargeback Fee Rule",
        component: () =>
          import("@/views/Dashboard/SingleChargebackFeeRule.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/chargebacks/:uuid",
        name: "Single Chargeback",
        component: () =>
          import("@/views/Dashboard/Chargeback/SingleChargeback.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/documents",
        name: "documents",
        component: () => import("@/views/Dashboard/Documents/Documents.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/alias",
        name: "Aliases",
        component: () => import("@/views/Dashboard/Alias/Alias.vue"),
        props: (route) => ({
          user: route.query.user,
          enroller: route.query.enroller,
        }),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/alias/:username/version/:version",
        name: "Alias",
        component: () => import("@/views/Dashboard/Alias/SingleAlias.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/recovery-password",
        name: "recovery-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/RecoveryPassword.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.beforeResolve((to, from, next) => {
  if (to.meta.requiresAuth) {
    const path = to.path.split("/").filter((e) => e);
    if (path.length < 2) {
      if (_.includes(store.getters.Routes, "/" + path[0])) {
        next();
      } else {
        next("/dashboard");
      }
    } else {
      if (_.includes(store.getters.Routes, "/" + path[0] + "/*")) {
        next();
      } else {
        next("/dashboard");
      }
    }
  } else {
    next();
  }
});

export default router;
