import { App } from "vue";
import axios, {AxiosError} from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import router from "@/router";
import store from "@/store";
import moment from "moment/moment";
import axiosRetry from "axios-retry";

/**
 * @description service to call HTTP request via Axios
 */
interface Props {
  slug: string | null | any,
  params: Object | null | any
}
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static sending: boolean;
  public static failedRequest: any;
  private static excludeRetryUrls: string[] = [
    'auth/timestamp',
    'auth/login',
    'auth/logout',
    'auth/profile'
  ];

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {

    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    ApiService.sending = false;
    ApiService.failedRequest = [];

    axiosRetry(ApiService.vueInstance.axios, {
      retries: 1, // number of retries      
      retryDelay: (retryCount) => {
        return retryCount * 1500; // time interval between retries
      },      
      retryCondition: (error: any) => {        
        return !ApiService.excludeRetryUrls.includes(error.config.url || ''); //error.response.status === 503 && error.config.url != 'auth/logout'; // retry condition
      },
      
    });

    /**
     * Interceptor para las solicitudes y regenera el token si esta apunto de vencer
     */
    app.axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {

          if(ApiService.sending && config.url != 'auth/token-generate') {
            return config;
          }
          const tokenJson = localStorage.getItem('token');
          if (!tokenJson) return config;

          let tokenData = JSON.parse(tokenJson);

          const deadline = localStorage.getItem('deadline');

          const init = moment().utc();
          const end = moment(deadline).utc();
          const seconds = Math.round(moment.duration(end.diff(init)).asSeconds());
          const beforeLogutTime= process.env.VUE_APP_TIME_BEFORE_LOGOUT ? Number(process.env.VUE_APP_TIME_BEFORE_LOGOUT) : 240;

          if(end.isAfter(init) && seconds < beforeLogutTime && !ApiService.sending) {

            ApiService.sending = true;

            try{

              const email = window.localStorage.getItem('email');

              const { data } = await ApiService.post("auth/token-generate", { email });
              tokenData = data;

              localStorage.setItem('token', JSON.stringify(tokenData));
              store.commit(Mutations.SET_AUTH, tokenData);
              ApiService.setHeader();

              const broadcast = new BroadcastChannel('tabs_channel');
              broadcast.postMessage({ command: 'refreshCountdownAllExceptMe' });

              await store.dispatch("timer/setInactivityTime", new Date().getTime());

              ApiService.sending = false;

            }catch (e) {

              ApiService.sending = false;

            }


          }

          config.headers = { ...config.headers, Authorization: `Bearer ${ tokenData.token }` };

          return config;

        },
        error => {
          return Promise.reject(error);
        }
    );

    /**
     * Interceptor para las respuestas y cerrar sesion cuando el token vence
     */
    app.axios.interceptors.response.use(
        async (response) => {
          return response;
        },
        (err) => {
          
          if (err?.response?.status === 401 && err.config.url === 'auth/token-generate') {

            store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));

          }        

          return Promise.reject(err);          

        }
    );

    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: Object
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    //props: Props
    params = {} as Object,
    //headers = {} as Object,
    slug = "" as string,
  ): Promise<AxiosResponse> {
    if(slug) return ApiService.vueInstance.axios.get(`${resource}/${slug}`, params);
    return ApiService.vueInstance.axios.get(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: any
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static send(
    params: any
  ): Promise<any> {
    return ApiService.vueInstance.axios(params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
